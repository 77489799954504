html {
  scroll-behavior: smooth;
}

body {
  color: #344767 !important;
}

.v-card{
  border-radius: 1rem !important;
 box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%) !important;
 border-width: thin;
 display: block;
 max-width: 100%;
 outline: none;
 text-decoration: none;
 transition-property: box-shadow,opacity;
 overflow-wrap: break-word;
 position: relative;
 white-space: normal;

}

#price .v-card {
  min-height: 770px;
}

#price .v-card.second-row {
  min-height: 770px;
}

#price.whistleblower .v-card.second-row {
  min-height: 650px;
}

.flip-card {
  background-color: transparent;
  width: 100%;
  height: 260px;
  perspective: 1000px;
}


/* common */
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #761919;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #A82424;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font: 700 18px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
}

/* top right*/
.ribbon-top-right {
  top: -10px;
  right: -10px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}

.splide__track.splide__track--slide {
  width: 100%;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;

}

.is-open .kachel {
  overflow: hidden;
  height: auto;
}

.kachel {
  overflow: hidden;
  height: 0;
  transition: 0.2s;

}

.timeline {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

#price .kombi {
  height: auto;
  min-height: auto !important;
}

#price .kombi a {
  position: initial;
}

button.accordion {
  border-radius: 0 !important;
}

#price .accordion-content {
  padding: 0;
}

button.accordion svg {
  height: 20px;
    float: right;
    transition: .2s;
}

button.accordion.is-open svg {
  transform: rotate(180deg);
}

.timeline .container {
  width: 100%;
  max-width: 1500px;
  height: 100vh;
  display: flex;
}

.timeline span {
  font-size: 3em;
  font-weight: bold;
  opacity: .4;
  position: absolute;
  right: 0;
  top: 0;

}

.timeline p {
  font-size: 2.4em;
  text-align: center;
}

.timeline .icon {
  width: 25% !important;
}

@media (max-width: 720px) {

  
.timeline .icon {
  width: 65% !important;
}
  
}

.v-card.has-background-primary p {
  font-size: 1.4em;
}

.left,
.right {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: fuchsia; */
}

.left {
  /* background-color: green; */
  justify-content: space-between;
}

.right ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-left: 2rem;
}

.right ul li {
  height: 15px;
  width: 15px;
  border-radius: 100px;
  border: 1px solid $primary;
}

.right ul li:first-child {
  background-color: $primary;
}

.images {
  height: 100%;
  width: 100%;
  max-height: 300px;
  max-width: 1200px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.images .timeline-holder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: 1s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.animated, .js-animated {
  visibility: visible !important;
}

.is-animated {
  visibility: hidden;
}

.images .timeline-holder:first-child {
  opacity: 1;
}


.tile-container {
  cursor: pointer;
}

.tile-container:hover .kachel {
  height: auto;
}

.tile-container  img{
 border-radius: 10px;
}

.timeline-content p {
  font-size: 2.75em;
}

.timeline .timeline-item {
  padding-bottom: 6em;
}

.timeline .timeline-item .timeline-content {
  padding: 1em 0 0 5em;
}

.timeline .timeline-item .timeline-marker.is-icon {
  width: 120px;
  height: 120px;
}

#price a {
  position: absolute;
    left: 0%;
    bottom: 10px;
    width: 100%;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card:hover .flip-card-text {
  display: none;
}

.flip-card-text {
  z-index: 1;
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  border-radius: 1rem !important;
  box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%) !important;
  background-color: #dedede;
  color: black;

}

.flip-card-back {
  border-radius: 1rem !important;
  box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%) !important;
  background-color: $primary;
  color: white;
  transform: rotateY(180deg);
  font-size: .8em;
  text-align: left;
}

.flip-card-back ul li {
  list-style-type:none ;
  margin-bottom: 5px;
}

.flip-card-back ul li::before {

  width: 15px;
  height: 15px;
  display: block;
  float: left;
  margin-left: -20px;
}

.select select, .textarea, .input {
  border: 1px solid #ccc;
  padding:25px 10px;
}

.offers {
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.1s;
  border: 1px solid #de272b;
}

.benefits-list li:before {
  background-image: url('../../media/logo_icon.png');
  background-size: contain;
  display: inline-block;
  width: 12px; 
  height: 12px;
  content:"";
  margin-right: 12px;
}

.benefits-list li {
  margin-bottom: 6px;

}

.navbar-brand img.logo-text {
  padding: 22px !important
}

.navbar-brand img {
  padding: 12px !important
}

.navbar.is-fixed-top {
  height: 90px
}

#logo a {
  height: 90px
}

.navbar-burger {
  display: block;
  height: auto;
  color: #111;
  position: relative;
  margin: 0;
  z-index: 1000
}

.navbar-mega {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #de272b;
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 999;
  top: -100vh;
  transition: .5s ease-in-out
}

.splide__track.splide__track--slide {
  width: 100%;
}

#contact .form {
  min-height: 660px;
}


.navbar-burger span {
  height: 2px;
  width: 20px;
}

#navbarKGFK {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between
}

.navbar-mega.is-active {
  top: 0
}

.jarallax {
  height: 100%;
}

.benefits {
  min-height: 260px;
}

.pricing {
  font-size: .8em;
}

.pricing li {
  padding: 10px 0;
  height: 50px;
}

.v-card .has-background-primary {
  border-radius: 1rem 1rem 0 0;
}

.v-card h3 {
  min-height: 100px;
}

.v-card .icon {
width: 60px;
height: auto;
}

.v-card .icon svg {
  color: #fff;
}

.pricing li:nth-of-type(odd) {
  background-color: #ddd;
}

.v-card.benefits:hover {
  transform: rotateY(180deg);
}

.benefit-number {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 16rem;
  font-weight: 800;
  color: #f8f8f8;
  line-height: 1;
  z-index: 0;
}


.hero-image {
  position: absolute;
  max-height: 115%;
  top: 0;
  right: 20px;
}

@media(max-width:820px) {
  .hero.is-fullheight {
    min-height: 50vh;
    }

    .hero-image {
      opacity: .7;
    }
}




button.accordion {
  width: 100%;

  border: none;
  outline: none;
  text-align: left;
  padding: 15px 20px;
  font-size: 18px;
  color: #333;
  cursor: pointer;
  transition: background-color 0.2s linear;
}


.hero-text-container {
  max-width: 900px;
}


button.accordion:hover,
button.accordion.is-open {
  background-color: #ddd;
}

.accordion-content {
  background-color: white;
  border-left: 1px solid whitesmoke;
  border-right: 1px solid whitesmoke;
  padding: 0 20px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
}