.navbar-brand img.logo-text {
    padding: 22px !important;
  }
  
  .navbar-brand img {
    padding: 12px !important
  }
  
  .navbar.is-fixed-top {
    height: 90px;
  }
  
  #logo a {
    height: 90px;
  }
  
  .columns {
    margin: 0 !important;
  }
  
  .has-signature {
    padding: 0 0 0 20px;
    background-position: 87% 92%;
    background-repeat: no-repeat;
    background-size: contain;
  }
  
  section.has-benefits .column {
    min-height: 350px;
    -webkit-filter: brightness(100%);
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    -ms-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
  }
  

  
  .navbar-item,
  .navbar-link {
    color: #fff;
  }
  
  section.has-benefits .column:hover .hover-headline {
    display: none;
  }
  
  section.has-benefits .column:hover .hover-text {
    display: flex;
  }
  
  .content-slider {
    width: 100%;
    height: 360px;
  }
  
  .slider {
    height: 320px;
    width: 50%;
    margin: 40px auto 0;
    overflow: visible;
    position: relative;
  }
  
  .mask {
    overflow: hidden;
    height: 320px;
  }
  
  .slider ul {
    margin: 0;
    padding: 0;
    position: relative;
  }
  
  .slider li {
    width: 100%;
    height: 320px;
    position: absolute;
    top: -325px;
    list-style: none;
  }
  
  .slider .quote {
    font-size: 3em;
    text-align: center;
    font-weight: 900;
  }
  
  .slider .source {
    font-size: 1.5em;
    text-align: center;
  }
  
  .slider li.anim1 {
    animation: cycle 20s linear infinite;
  }
  
  .slider li.anim2 {
    animation: cycle2 20s linear infinite;
  }
  
  .slider li.anim3 {
    animation: cycle3 20s linear infinite;
  }
  
  .slider li.anim4 {
    animation: cycle4 20s linear infinite;
  }
  
  .slider li.anim5 {
    animation: cycle5 20s linear infinite;
  }
  
  
  
  @keyframes cycle {
    0% {
      top: 0px;
    }
  
    4% {
      top: 0px;
    }
  
    16% {
      top: 0px;
      opacity: 1;
      z-index: 0;
    }
  
    20% {
      top: 325px;
      opacity: 0;
      z-index: 0;
    }
  
    21% {
      top: -325px;
      opacity: 0;
      z-index: -1;
    }
  
    50% {
      top: -325px;
      opacity: 0;
      z-index: -1;
    }
  
    92% {
      top: -325px;
      opacity: 0;
      z-index: 0;
    }
  
    96% {
      top: -325px;
      opacity: 0;
    }
  
    100% {
      top: 0px;
      opacity: 1;
    }
  }
  
  @keyframes cycle2 {
    0% {
      top: -325px;
      opacity: 0;
    }
  
    16% {
      top: -325px;
      opacity: 0;
    }
  
    20% {
      top: 0px;
      opacity: 1;
    }
  
    24% {
      top: 0px;
      opacity: 1;
    }
  
    36% {
      top: 0px;
      opacity: 1;
      z-index: 0;
    }
  
    40% {
      top: 325px;
      opacity: 0;
      z-index: 0;
    }
  
    41% {
      top: -325px;
      opacity: 0;
      z-index: -1;
    }
  
    100% {
      top: -325px;
      opacity: 0;
      z-index: -1;
    }
  }
  
  @keyframes cycle3 {
    0% {
      top: -325px;
      opacity: 0;
    }
  
    36% {
      top: -325px;
      opacity: 0;
    }
  
    40% {
      top: 0px;
      opacity: 1;
    }
  
    44% {
      top: 0px;
      opacity: 1;
    }
  
    56% {
      top: 0px;
      opacity: 1;
      z-index: 0;
    }
  
    60% {
      top: 325px;
      opacity: 0;
      z-index: 0;
    }
  
    61% {
      top: -325px;
      opacity: 0;
      z-index: -1;
    }
  
    100% {
      top: -325px;
      opacity: 0;
      z-index: -1;
    }
  }
  
  @keyframes cycle4 {
    0% {
      top: -325px;
      opacity: 0;
    }
  
    56% {
      top: -325px;
      opacity: 0;
    }
  
    60% {
      top: 0px;
      opacity: 1;
    }
  
    64% {
      top: 0px;
      opacity: 1;
    }
  
    76% {
      top: 0px;
      opacity: 1;
      z-index: 0;
    }
  
    80% {
      top: 325px;
      opacity: 0;
      z-index: 0;
    }
  
    81% {
      top: -325px;
      opacity: 0;
      z-index: -1;
    }
  
    100% {
      top: -325px;
      opacity: 0;
      z-index: -1;
    }
  }
  
  @keyframes cycle5 {
    0% {
      top: -325px;
      opacity: 0;
    }
  
    76% {
      top: -325px;
      opacity: 0;
    }
  
    80% {
      top: 0px;
      opacity: 1;
    }
  
    84% {
      top: 0px;
      opacity: 1;
    }
  
    96% {
      top: 0px;
      opacity: 1;
      z-index: 0;
    }
  
    100% {
      top: 325px;
      opacity: 0;
      z-index: 0;
    }
  }
  
  
  .Image-Portfolio .column {
    padding: 0;
  
    overflow: hidden;
  }
  
  .Image-Portfolio .column img {
    transition: .5s ease-in-out;
  }
  
  .Image-Portfolio .column:hover img {
    transform: scale(1.1);
  }
  
  .Image-Portfolio.section {
    padding: 0;
  }
  
  .has-extra-line-height {
    line-height: 1.8em;
  }
  
  .jarallax {
    position: relative;
    z-index: 0;
    min-height: 400px;
  }
  
  .jarallax>.jarallax-img {
    position: absolute;
    object-fit: cover;
    /* support for plugin https://github.com/bfred-it/object-fit-images */
    font-family: 'object-fit: cover;';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  
  
  /* Style the buttons that are used to open and close the accordion panel */
  .accordion {
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.1s;
  }
  
  .mandantenkategorieteaser-header {
    display: block;
    width: 100%;
    height: 10px;
    margin: 0 !important;
    border-radius: 5px 5px 0 0;
    background-color: #108cc7;
  }
  
  #lawyerteaser .splide__slide {
    flex-direction: column;
  }
  
  #lawyerteaser .splide__pagination {
    display: none;
  }
  
  #lawyerteaser a {
    width: 100%;
  }
  
  /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
  .active,
  .accordion:hover {
    background-color: #004E7A;
  }
  
  /* Style the accordion panel. Note: hidden by default */
  .panel {
    padding: 0 18px;
    max-height: 0;
    overflow: hidden;
    transition: max-height .6s;
  }
  
  

  .expert .splide__slide {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
  }


  
  
  
  .jarallax-50-50 {
    padding: 0;
  }
  
  .jarallax-50-50 .column {
    padding: 0;
  }
  
  .jarallax-50-50 .jarallax {
    min-height: 75vh;
  }
  
  
  .custom-btn {
    width: 130px;
    height: 40px;
    padding: 10px 25px;
    margin-top: 10px;
    border: 2px solid #fff;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    float: right;
    color: #fff;
  }
  
  .col-sm-2.control-label {
    font-weight: 300;
  }
  
  .btn-3 {
    line-height: 39px;
    padding: 0;
  }
  
  .btn-3:hover {
    background: transparent;
    color: #fff;
  }
  
  .btn-3 span {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
  }
  
  .btn-3:before,
  .btn-3:after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    background: #fff;
    transition: all 0.3s ease;
  }
  
  .btn-3:before {
    height: 0%;
    width: 2px;
  }
  
  .btn-3:after {
    width: 0%;
    height: 2px;
  }
  
  .btn-3:hover:before {
    height: 100%;
  }
  
  .btn-3:hover:after {
    width: 100%;
  }
  
  .btn-3 span:before,
  .btn-3 span:after {
    position: absolute;
    content: "";
    right: 0;
    bottom: 0;
    background: #fff;
    transition: all 0.3s ease;
  }
  
  .btn-3 span:before {
    width: 2px;
    height: 0%;
  }
  
  .btn-3 span:after {
    width: 0%;
    height: 2px;
  }
  
  .btn-3 span:hover:before {
    height: 100%;
  }
  
  .btn-3 span:hover:after {
    width: 100%;
  }
  
  .hover-headline {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  
  .hover-text {
    width: 100%;
    height: 100%;
    position: absolute;
    display: none;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  


  
  #navbarKGFK {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
  }
  
  .navbar-mega.is-active {
    top: 0;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: bold;
  }
  
  .navbar-brand img.logo-text {
    padding: 27px 22px !important;
  }
  
  .navbar-brand img {
    padding: 12px !important
  }
  

  
  #logo a {
    display: flex;
    width: auto;
  }
  
  .navbar-brand img {
    padding: 22px;
  }
  
  .logo-seperator {
    border: none;
    border-left: 1px solid #279ad6;
    height: 100%;
    width: 1px;
    margin: 0;
  }
  
  
  .navbar-item img {
    max-height: 100%;
    height: 32px;
  }
  
  .navbar-item figure {
    margin-right: 5px;
  }
  
  
  
  
  
  .is-active .animate__animatedx {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-duration: var(--animate-duration);
    animation-duration: var(--animate-duration);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  
  .is-active .animate__animatedx:nth-of-type(1) {
    animation-delay: .2s;
  }
  
  .is-active .animate__animatedx:nth-of-type(2) {
    animation-delay: .5s;
  }
  
  .is-active .animate__animatedx:nth-of-type(3) {
    animation-delay: .8s;
  }
  
  .is-active .animate__animatedx:nth-of-type(4) {
    animation-delay: 1.1s;
  }
  
  
  .is-active .animate__backInDownx {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
  }
  
  .is-active .animate__backInRightx {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    animation-delay: 1.1s;
  }
  
  .p-8 {
    padding: 8rem;
  }
  
  .navbar-item.is-flex.is-3.column.is-justify-content-center a {
    width: 220px;
  }
  
  .navbar-burger span {
    height: 2px;
    width: 20px;
  }
  
  .ce-bodytext p {
    padding-bottom: .5em;
  }
  
  #leftColumn p {
    padding-bottom: .5em;
    font-size: 1rem;
  }
  
  .offers:hover .page {
    color: #222;
  }
  
  
  .active .icon {
    transform: rotate(180deg);
  }
  

  
  .news-related-files-link a::before {
    width: 1rem;
    height: 1rem;
    color: #fff;
    position: relative;
    top: 5px;
  }
  
  .main-emphasis .offers {
    width: 46%;
    float: left;
  }
  

  .input:hover {
    border: 2px solid transparent;
  }
  

  
  .form .input {
    padding: 0 !important;
    height: auto;
  }
  
  input,
  select,
  textarea {
    color: #fff;
  }

  .hover-headline {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px
  }
  
  .hover-text {
    width: 100%;
    height: 100%;
    position: absolute;
    display: none;
    align-items: center;
    justify-content: center;
    padding: 20px
  }
  
  textarea:focus,
  input:focus {
    color: #fff;
  }
  
  .table-responsive td {
    color: #fff;
  }
  
  .table td,
  .table th {
    border: none;
  }